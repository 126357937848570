import React from 'react'

import { Link } from 'gatsby'

const Exhibitionnav = ({children}) => (
    <nav>
        <p class="exhibition_desc">{children}</p>
    </nav>
)

export default Exhibitionnav